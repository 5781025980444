/* Light Mode Styles */

.container {
  background: #fff; /* Light mode background gradient */
}

.text {
  color: #333; /* Dark text color for light mode */
}

.paragraph {
  color: #666; /* Lighter text color for paragraphs */
}

.softBuilt {
  color: #4573df; /* Highlighted color */
}

@media (max-width: 1024px) {
  .text {
    color: #333;
  }

  .paragraph {
    color: #666;
  }
}
