/* Dark Mode Styles */

.container {
  background: #1d2127; /* Dark mode background gradient */
}

.text {
  color: #ffffff; /* Light text color for dark mode */
}

.paragraph {
  color: #cccccc; /* Lighter text color for paragraphs */
}

.softBuilt {
  color: #4573df; /* Highlighted color */
}

@media (max-width: 1024px) {
  .text {
    color: #ffffff;
  }

  .paragraph {
    color: #cccccc;
  }
}
