/* SkillFrame container */
.skillFrame {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px; /* Padding around the skill cards */
    box-sizing: border-box;
    gap: 20px; /* Gap between skill cards */
    padding-left: 6%; /* Left padding */
    padding-right: 6%; /* Right padding */
    padding-bottom: 5%;
    
}

/* Skills section title */
.skillsTitle {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px; /* Adjust font size */
    font-weight: bold;
    margin-bottom: 20px; /* Space between the title and the skill cards */
    text-align: left;
    box-sizing: border-box;
    display: flex; /* Allow for flex layout */
    align-items: center; /* Align items vertically */
}

/* Title icon styling */
.titleicon {
    width: 30px; /* Set width for the title icon */
    height: 30px; /* Set height for the title icon */
    margin-right: 10px; /* Add margin to separate icon from text */
}

/* Individual skill card */
.skillCard {
    width: 100%;
    max-width: 336px; /* Maximum width of the skill card */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Padding inside the skill card */
    padding-top: 20px;
    box-sizing: border-box;
    position: relative;
    margin-top: 20px; /* Adding margin from the top */
    transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.skillCard:hover {
    transform: translateY(-5px); /* Move the card up slightly on hover */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}
.uiuxCard .icon{
    width: 55px;
    max-width: 97px; /* Maximum width of the icon */
    height: auto ; /* Height of the icon */
    object-fit: contain;

}

.developmentCard .icon{
    width: 55px;
    max-width: 97px; /* Maximum width of the icon */
    height: auto ; /* Height of the icon */
    object-fit: contain;
    
}



/* Header styling */
.header {
    position: relative;
    width: 100%; /* Adjusted width for mobile */
    height: 40px; /* Adjusted height to accommodate the icon */
    display: flex;
    align-items: center; /* Center align the icon and text */
    justify-content: flex-start; /* Align items to the start */
    font-size: 20px;
    font-family: 'Open Sans';
}

/* Title styling */
.title {
    margin: 0;
    text-align: centre;
    padding-left:10%;
}

/* Skill row container */
.skillRow {
    display: flex;
    align-items: center;
    margin-top: 12px;
    position: relative;
}

/* Container for skill name and progress bar */
.skillNameContainer {
    display: flex;
    flex-direction: column;
    margin-left: auto; /* Move skill name container to the right */
    position: relative;
   /* Background color for the skill name container */
    border-radius: 3px;
    padding: 2px 3px;
    box-sizing: border-box;
    width: 210px; 
    height: 30px;   
}

/* Skill name text */
.skillName {
    font-weight: 600;
    font-size: 18px;
}

/* Icons container */
.icons {
    display: flex;
    justify-content: space-around;
}

/* Individual icon */
.icon {
    width: auto;
    max-width: 97px; /* Maximum width of the icon */
    height: 30px; /* Height of the icon */
    object-fit: contain;
}


  /* Keyframes for fade-in animation */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Animation class to apply */
  .animateIn {
    animation: fadeInUp 0.6s ease-out;
  }
  
  /* Ensure cards are initially invisible until animated */
  .skillCard {
    opacity: 0; /* Initial state */
    transition: opacity 0.6s ease-out; /* Transition effect for opacity */
  }
  
  /* Once animated, the card remains visible */
  .skillCard.animateIn {
    opacity: 1;
  }
  
/* Media query for smaller screens */
@media (max-width: 768px) {
    /* Adjustments for smaller screens */
    .skillFrame {
        justify-content: center; /* Center align skill cards */
    }
    
    .skillsTitle {
        padding-left: 3%; /* Adjust padding for smaller screens */
    }


    
}
